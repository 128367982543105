import { WebService } from './webservice.model';
import { Agencia } from '../cadastro/agencia.model';

export class BloqueioAereo {
  public Id: number;
  public Descricao: string;
  public CiaValidadora: string;
  public Tarifa: number;
  public ValorOver: number;
  public MoedaId: number;
  public Markup: any;
  public Taxa: number;
  public ValorFee: number;
  public Bagagem: number;
  public Status: number;
  public Tipo: string;
  public EmailPrazo: string;
  public ValorDevolvido: number;
  public AssentosDevolvidos: number;
  public LocalizadorAereo: string;
  public ObservacaoInterna: string;
  public ObservacaoVoucher: string;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataIn: any;
  public DataOut: any;
  public PrazoEmissaoAte: any;
  public PrazoPagamentoParcial: any;
  public PrazoPagamentoIntegral: any;
  public TotalAssentos: number;
  public Ativo: any;
  public Alteracoes: any;
  public Voos: BloqueioAereoVoo[];
  public AgenciasExclusivas : Agencia[];
  public Pacote : any;
  public WebService?: any = {Descricao : ''};
  public WebServiceId: number;
  public BloqueioPoliticaVoucher: BloqueioPoliticaVoucher;
  public BloqueioPoliticaVoucherId: number;
}

export class BloqueioAereoVoo {
  public Id: number;
  public NumeroVoo: number;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: any;
  public DataChegada: any;
  public HoraIn: any;
  public HoraOut: any;
  public Cia: string;
  public ClasseTarifaria: string;
  public Equipamento: string;
  public ARUNK: any;
  public BloqueioAereoId : number;
  public Ordem: number;
}

export class BloqueioAereo_Agencia {
  public Agencia?: any = {NomeFantasia : ''};
}

export class BloqueioAereo_ConsultaCriterios {
  public Agencia?: any = {NomeFantasia : ''};
}

export class BloqueioPoliticaVoucher {
  public Id: number;
  public IATA: string;
  public Titulo: string;
  public Descricao: string;
}