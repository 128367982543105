import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GERENCIAMENTO } from '../../app.config';
import { AgenciaWB } from '../../model';

@Injectable()
export class BannerAgenciaWhiteLabelService {

  constructor(private http: HttpClient) { }

  buscarBannerWhiteLabelPorAgenciaId(agenciaId: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/bannerwhitelabel/buscarbanners/${agenciaId}`);
  }

  uploadBannerWhiteLabel(Imagem: any, bannerWB: AgenciaWB): Observable<any> {

    const url = `${GERENCIAMENTO}/api/bannerwhitelabel/uploadbanner`;

    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', Imagem);
    formData.append('Dados', JSON.stringify(bannerWB));

    return this.http.post(url, formData, { headers: headers } );

  }

  excluirBannerWhiteLabel(id: Number): Observable<any> {
    return this.http.delete(`${GERENCIAMENTO}/api/bannerwhitelabel/removerbanner/${id}`);
  }
}
