import { Cidade } from "../endereco.model";

export class Circuito {
    public Id: number;
    public Titulo: string;
    public Cidade: any = { Nome: "" };
    public Pais: any = { Nome: "" };
    public ImagemPrincipal: string;
    public CodigoCliente: any;
    public CircuitoQuartos: CircuitoQuartos;
    public Tipo: string;
    public Temporada: string;
    public ExtrasCircuito: any;
    public Fornecedor: string;
    public CodigoCircuito: string;
}

export class ReservarCircuitoRQ {
    public Circuito: any;
    public Token: string = "";
    public TrasladoPartida: TrasladoDados = new TrasladoDados();
    public TrasladoChegada: TrasladoDados = new TrasladoDados();
    public ExtrasCircuito: string = "";
}

export class DetalhesCircuito {
    public Descricao: string;
    public Dias: number;
    public Inclui: string;
}

export class DayByDay {
    public Titulo: string;
    public Descricao: string;
    public Dia: number;
}

export class BuscarCircuitoRQ {
    public FiltroCircuito: FiltroCircuito = new FiltroCircuito();
    public Cidade: Cidade = new Cidade();
    public Saida: any;
    public Chegada: any;
    public PorPais = false;
    public Pais;
    public Quartos: number;
    public Guid: string;
    public AgenciaId: number;
    public Pessoas: any;
    public Fornecedores: string[] = [];
    public Temporada: any;
}

// export class DadosCircuitosRQ {
//     public DataSaida: any;
//     public Extra: any;
//     public Extra2: any;
// }

export class FiltroCircuito {
    public ValorMinimo: number = 0;
    public ValorMaximo: number = 0;
    public Fornecedores: string[] = [];
    public Paises: string[] = [];
    public Cidades: string[] = [];
    public Titulo: string = '';
    public CodigoCircuito: string = '';
    public Duracao: number = 0;
    public Refeicoes: number = 0;
}

export class PoliticaCircuitoRQ {
    public Circuito: any;
    public Agencia: any;
    public Status: number = 0;
    public PoliticaValida: boolean = false;
    public RefazerTarifa: boolean = false;
    public Token: string;
    public NoiteAdicionalServicoId: any;
    public NoitesAdicionais: any;
    public TrasladoServicoId: any;
    public VooNumero: string;
    public Companhia: string;
    public DataIncorporacao: string;
    public DataAbandono: string;
    public SalvarCircuito: boolean = false;
    public PreReservar: boolean = false;
    public ExtrasCircuito: string;
    public TrasladoPartida: TrasladoDados = new TrasladoDados();
    public TrasladoChegada: TrasladoDados = new TrasladoDados();
}

export class PoliticaCircuitoRS {
    public Circuito: any;
    public Status: number = 0;
    public Retarifado: boolean = false;
    public PoliticaValida: boolean = false;
    public PermiteMediaPensao: boolean = false;
    public PermiteNoitesAdicionais: boolean = false;
    public PermiteCircuitoFlexivel: boolean = false;
    public PermiteCircuitoRotativo: boolean = false;
    public PermiteStopsCircuito: boolean = false;
    public IncluiTransladoPartida: boolean = false;
    public IncluiTransladoChegada: boolean = false;
    public PoliticaCancelamento: string[] = [];
    public Abandonos: AbandonoIncorporacao[] = [];
    public Incorporacoes: AbandonoIncorporacao[] = [];
    public Traslados: Traslado[] = [];
    public NoitesAdicionais: NoiteAdicional[] = [];
    public Faturas: Fatura[] = [];
    public ValorTotal: number = 0;
    public Moeda: any;
    public PermiteReservar: boolean = false;
}

export class TrasladoDados {
    TipoTraslado: string = "";
    Voo: string = "";
    Opcao: string = "";
    Companhia: string = "";
    CompanhiaId: number = 0;
    Aeroporto: string = "";
    CidadeDestino: string = "";
    NomeHotel: string = "";
    EnderecoHotel: string = "";
    HorarioVoo: string = "";
    FaltaDados: string = "";
}

export class Fatura {
    public NomeProduto: string;
    public ValorProduto: number;
    public Moeda: any;
}


export class Traslado {
    public DataInicio: string;
    public DataFim: string;
    public DataSaida: string;
    public DataChegada: string;
    public PaisNome: string;
    public CidadeNome: string;
    public AerportoSaida: string;
    public Lugar: string;
    public AerportoChegada: string;
    public CompanhiaAerea: string = "";
    public ServicoId: undefined | any;
    public Voo: string = "";
    public TipoTraslado: string; // IN-OUT
    public Confirmado: boolean = false; // se o traslado foi confirmado
    public Existe: boolean = false; // se o traslado existe SPECIAL TOURS
    public ValorListagem: number = 0;
    public MoedaListagem: any;
    public ExtraTraslado: string;
}

export class NoiteAdicional {
    public DataInicio: any;
    public DataFim: any;
    public PaisNome: string;
    public CidadeNome: string;
    public ServicoId: any;
    public TipoNoite: string;
    public Descricao: string;
}

export class AbandonoIncorporacao {
    public Data: string;
    public CodigoSemana: string;
    public CidadeNome: string;
    public Ordem: number;
    public MoedaListagem: any;
    public ValorListagem: any;
}

export class TarifarCircuitoRQ {

    public Single: number = 0;
    public PaxSingle: PaxCircuito[] = [];
    public Double: number = 0;
    public PaxDouble: PaxCircuito[] = [];
    public Triple: number = 0;
    public PaxTriple: PaxCircuito[] = [];
    public Quadruple: number = 0;
    public PaxQuadruple: PaxCircuito[] = [];
    public Shared: number = 0;
    public PaxShared: PaxCircuito[] = [];

    public ReservaId: number = 0;
    public AgenciaId: number = 0;
    public Data: string = "";
    public CodigoCategoria: string = "";
    public CodigoCircuito: string = "";
    public CategoriaNome: string = "";
    public Circuito: any;
    public Retarifar: boolean = false;
    public ExtrasCircuito: string = "";
    public Token: string = "";

    constructor() { }
}

export class DetalhesCircuitoRQ {
    public Circuito: any;
    public AgenciaId: number;
    public DetalhesReserva: boolean = false;

    constructor() { }
}

export class Disponibilidade {

    CategoriaCodigo: string;
    CategoriaNome: string;
    Data: string;
    MoedaNet: [] = [];

    PrecoDouble: number = 0;
    PrecoSingle: number = 0;
    PrecoTriple: number = 0;
    PrecoShared: number = 0;
    PrecoQuadruple: number = 0;

    MoedaListagem: any;
    StatusDiponibilidade: any;
    QuantidadeDisponivel: any;
}

export class PaxCircuito {
    public DataNascimento: any = "";
    public Idade: number = 30;
}

export class IdadesCircuito {
    public Idades: number[] = [];
}

export class CircuitoQuartos {
    public PaxSingle: IdadesCircuito[] = [];
    public PaxDouble: IdadesCircuito[] = [];
    public PaxShared: IdadesCircuito[] = [];
    public PaxTriple: IdadesCircuito[] = [];
    public PaxQuadruple: IdadesCircuito[] = [];
}

export class CalendarioCircuito {
    public Mes: CalendarioMeses[] = [];

    constructor() {
        this.Mes[0] = { Numero: 1, Nome: "Janeiro", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[1] = { Numero: 2, Nome: "Fevereiro", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[2] = { Numero: 3, Nome: "Março", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[3] = { Numero: 4, Nome: "Abril", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[4] = { Numero: 5, Nome: "Maio", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[5] = { Numero: 6, Nome: "Junho", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[6] = { Numero: 7, Nome: "Julho", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[7] = { Numero: 8, Nome: "Agosto", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[8] = { Numero: 9, Nome: "Setembro", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[9] = { Numero: 10, Nome: "Outubro", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[10] = { Numero: 11, Nome: "Novembro", Disponibilidades: [], Ano: "Indefinido" };
        this.Mes[11] = { Numero: 12, Nome: "Dezembro", Disponibilidades: [], Ano: "Indefinido" };
    }

    // monthCalendar: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // monthCalendarName: string[] = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
}
export class CalendarioMeses {
    public Numero: number;
    public Ano: string;
    public Nome: string;
    Disponibilidades: Disponibilidade[] = [];
}

export class CompanhiaAerea {
    display_field: string = "";
    Nome: number;
    CompanhiaId: string;
    Iata: string;
    WebServiceId: number;
}

export class InfosCircuito {
    public NoitesInicio: number = 0;
    public NoitesFim: number = 0;
    public IncluiTrasladoChegada: boolean = false;
    public IncluiTransladoPartida: boolean = false;
    public CircuitoFlexivel: boolean = false;
    public OrdemCidadeIncorporacao: number = 0;
    public OrdemCidadeAbandono: number = 0;
    public CidadeIncorporacao: string = "";
    public CidadeAbandono: string = "";
    public TrasladoChegada: boolean = false;
    public TrasladoPartida: boolean = false;
    public LugarTrasladoChegada: string = "";
    public LugarTrasladoPartida: string = "";
    public DataInFlexivel: string = "2023-01-01";
    public DataOutFlexivel: string = "2023-01-01";
    public PreencherTrasladoIN: boolean = false;
    public PreencherTrasladoOUT: boolean = false;
}